import React from 'react';
import { initialState, ServiceReducer } from './reducer';

const ServiceStateContext = React.createContext();
const ServiceDispatchContext = React.createContext();

export const useServiceState = () => {
  const context = React.useContext(ServiceStateContext);
  if (context === undefined) {
    throw new Error('useServiceState can only be used within an AuthProvider');
  }
  return context;
};

export const useServiceDispatch = () => {
  const context = React.useContext(ServiceDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useServiceDispatch can only be used within an AuthProvider'
    );
  }
  return context;
};

export const ServiceProvider = ({ children }) => {
  const [service, dispatch] = React.useReducer(ServiceReducer, initialState);

  return (
    <ServiceStateContext.Provider value={service}>
      <ServiceDispatchContext.Provider value={dispatch}>
        {children}
      </ServiceDispatchContext.Provider>
    </ServiceStateContext.Provider>
  );
};
