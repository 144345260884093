import React, { useState, useEffect } from 'react';
import Page from 'src/components/Page';
import { Box, Typography, Button, Divider, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useAuthState } from 'src/context';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import Footer from 'src/components/Footer';
import Table from './ServiceList';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from 'src/components/Snackbar';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Skeleton from '@mui/material/Skeleton';

const useStyles = makeStyles(theme => ({
  root: { marginBottom: 100 },
  inputfield: {
    width: '100%'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  buttons: {
    backgroundColor: '#ffffff',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
    border: '1px solid #ced4da',
    borderRadius: 20,
    height: 25,
    marginRight: 4,
    '&:hover': {
      color: '#fff',
      backgroundColor: '#000000e3',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
      border: '1px solid #ffffff'
    }
  },
  button: {
    display: 'block',
    margin: '10px 0px 10px 0px',
    width: 200,
    '&:hover': {
      color: '#fff',
      backgroundColor: '#000000e3',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
      border: '1px solid #ffffff'
    }
  },
  button2: {
    height: 45,
    borderRadius: 8,
    backgroundColor: '#000000e3',
    '&:hover': {
      backgroundColor: '#000000e3',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
      color: '#fff'
    }
  },
  cardButton: {
    height: 45,
    color: '#fff',
    width: 350,
    borderRadius: 8,
    textTransform: 'capitalize',
    backgroundColor: '#000000e3',
    '&:hover': {
      backgroundColor: '#000000e3',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
      color: '#fff'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  pIcons: {
    color: '#8b8b95',
    height: 30,
    width: 30,
    marginTop: 15
  },
  boxOne: {
    alignItems: 'center',
    width: 400,
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  },
  boxTwo: {
    alignItems: 'center',
    width: 400,
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  },
  mainBox: {
    marginTop: 10,
    width: '400px',
    borderRadius: 4,
    padding: 25,
    zIndex: 1000,
    [theme.breakpoints.down('md')]: {
      width: 'auto',
      marginTop: 20
    }
  },
  textBox: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center'
    },
    marginTop: 10,
    display: 'inline-flex',
    alignItems: 'center'
  },
  firstBox: {
    marginTop: 10,
    width: '400px',
    borderRadius: 4,
    padding: 25,
    marginLeft: 20,
    zIndex: 1000,
    [theme.breakpoints.down('md')]: {
      width: 'auto',
      marginTop: 20
    }
  },
  paymentBox: {
    width: 350,
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  },
  circularProgress: {
    color: '#fff'
  },
  text: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    }
  },
  cancelBtn: {
    fontSize: 14,
    textTransform: 'none',
    color: '#c10e0e',
    textDecoration: 'underline',
    margin: 0,
    padding: 0,
    '&:hover': {
      backgroundColor: '#fff'
    }
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1.5px solid #d6d8dd',
    fontSize: 16,
    width: '100%',
    height: '20px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    borderRadius: 7,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    marginTop: 0,
    // Use the system font instead of the default Roboto font.
    fontFamily: ['Ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#a7aecf',
      borderRadius: 10
    },
    '&:hover': {
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      // borderColor: '#e3e8fd',
      borderRadius: 10
    }
  }
}));

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5'
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)'
  }
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#3f51b5',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""'
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3'
  }
});

// Inspired by blueprintjs
function BpRadio(props) {
  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent'
        }
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const LinkView = () => {
  const classes = useStyles();

  const { token } = useAuthState();

  const params = useParams();

  const [data, setData] = useState([]);

  const fdisplay = true;

  const [snack, setSnack] = useState(false);
  const [esnack, setESnack] = useState(false);

  const [errorOne, setErrorOne] = useState(false);
  const [eresponseOne, setEresponseOne] = useState(null);
  const [isLoadingOne, setIsLoadingOne] = useState(false);

  const [errorThree, setErrorThree] = useState(false);
  const [eresponseThree, setEresponseThree] = useState(null);
  const [isLoadingThree, setIsLoadingThree] = useState(false);

  const [cardError, setCardError] = useState(false);
  const [cardResponseError, setcardResponseError] = useState(null);
  const [isCardButtonLoading, setIsCardButtonLoading] = useState(false);

  const [load, setLoad] = useState(true);

  const id = params?.id;

  useEffect(() => {
    const fetchProductData = async id => {
      await axios({
        method: 'get',
        url: 'https://api.fapshi.com/donation/' + id,
        headers: { 'x-access-token': token }
      })
        .then(res => {
          setLoad(false);
          setData(res.data);
        })
        .catch(error => {
          console.log(error);
          navigate('/', { replace: true });
        });
    };
    fetchProductData(id);
  }, []);

  const [variableAmount, setVariableAmount] = useState(0);
  const [quantity, setQuantity] = useState(0);

  const [isCode, setIscode] = useState(true);
  const [code, setCode] = useState(null);

  const [inputs, setInputs] = useState({
    vendorName: null,
    amount: null,
    clientName: ' ',
    email: ' ',
    phone: ' '
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setInputs({ ...inputs, [name]: value });
  };

  const handleMomo = () => {
    setIsLoadingOne(true);
    const values = {
      amount: parseInt(inputs?.amount) || parseInt(variableAmount),
      clientName: inputs?.clientName,
      email: inputs?.email,
      phone: inputs?.phone
    };
    axios({
      method: 'post',
      url: 'https://api.fapshi.com/donation/momo/' + id,
      data: values
    })
      .then(() => {
        setIscode(false);
        setIsLoadingOne(false);
        setErrorOne(false);
      })
      .catch(err => {
        if (err.response.status === 402) {
          axios({
            method: 'get',
            url:
              'https://api.fapshi.com/transaction/product/' +
              err.response.data.uuid
          })
            .then(res => {
              console.log(res.data);
              if (res.data.status === 'SUCCESSFUL') {
                setSnack(true);
                navigate('/success/' + res.data.transferId, { replace: true });
              } else if (res.data.status === 'FAILED') {
                setESnack(true);
              }
              setIscode(true);
              setInputs(' ');
              setIsLoadingOne(false);
              setCode(null);
            })
            .catch(err => {
              setIsLoadingOne(false);
              console.log(err);
              setCode(null);
            });
        } else {
          setIsLoadingOne(false);
        }
        setErrorOne(true);
        setEresponseOne(err.response?.data.message);
      });
  };

  const handleQuantityMomo = () => {
    setIsLoadingOne(true);
    setEresponseOne(null);
    const values = {
      amount: parseInt(inputs?.amount) || parseInt(variableAmount),
      clientName: inputs?.clientName,
      email: inputs?.email,
      phone: inputs?.phone,
      quantity: parseInt(quantity)
    };
    axios({
      method: 'post',
      url: 'https://api.fapshi.com/donation/momo/' + id,
      data: values
    })
      .then(() => {
        setIscode(false);
        setIsLoadingOne(false);
        setErrorOne(false);
      })
      .catch(err => {
        if (err.response.status === 402) {
          axios({
            method: 'get',
            url:
              'https://api.fapshi.com/transaction/product/' +
              err.response.data.uuid
          })
            .then(res => {
              console.log(res.data);
              if (res.data.status === 'SUCCESSFUL') {
                setSnack(true);
                navigate('/success/' + res.data.transferId, { replace: true });
              } else if (res.data.status === 'FAILED') {
                setESnack(true);
              }
              setIscode(true);
              setInputs(' ');
              setIsLoadingOne(false);
              setCode(null);
            })
            .catch(() => {
              setIsLoadingOne(false);
              setCode(null);
            });
        } else {
          setIsLoadingOne(false);
        }
        setErrorOne(true);
        setEresponseOne(err.response?.data.message);
      });
  };

  const handleSMS = () => {
    setEresponseOne(null);
    setEresponseThree(null);
    setIsLoadingThree(true);
    const values = {
      code,
      phone: inputs?.phone
    };
    axios({
      method: 'post',
      url: 'https://api.fapshi.com/product/confirmsmscode',
      data: values
    })
      .then(res => {
        axios({
          method: 'get',
          url: 'https://api.fapshi.com/transaction/product/' + res.data.uuid
        })
          .then(res => {
            console.log(res.data);
            if (res.data.status === 'SUCCESSFUL') {
              setSnack(true);
              navigate('/success/' + res.data.transferId, { replace: true });
            } else if (res.data.status === 'FAILED') {
              setESnack(true);
            }
            setIscode(true);
            setInputs(' ');
            setIsLoadingThree(false);
            setCode(null);
          })
          .catch(() => {
            setIsLoadingThree(false);
            setEresponseThree(null);
            setCode(null);
          });
      })
      .catch(err => {
        setIsLoadingThree(false);
        setErrorThree(true);
        setEresponseThree(err.response?.data.message);
      });
  };
  const handleCardSubmit = () => {
    setIsCardButtonLoading(true);
    const values = {
      clientName: inputs?.clientName,
      email: inputs?.email,
      amount: parseInt(inputs?.amount) || parseInt(variableAmount),
      quantity: parseInt(quantity) || undefined
    };
    axios({
      method: 'post',
      url: 'https://api.fapshi.com/donation/card/' + id,
      data: values
    })
      .then(res => {
        window.location.assign(res.data.link);
      })
      .catch(err => {
        setIsCardButtonLoading(false);
        setCardError(true);
        setcardResponseError(err.response?.data.message);
      });
  };
  const handleCancel = () => {
    setIscode(true);
    setIsLoadingThree(false);
    setCode(null);
    setInputs(null);
    setEresponseOne(null);
    setEresponseThree(null);
  };
  const handleClose = () => {
    setSnack(false);
  };
  const handleEClose = () => {
    setESnack(false);
  };

  const PaymentsDisplay = () => {
    return (
      <Box className={classes.paymentBox}>
        {snack && (
          <Snackbar
            duration={6000}
            open={snack}
            close={handleClose}
            severity="success"
            data="Payments completed successfully"
          />
        )}
        {esnack && (
          <Snackbar
            duration={6000}
            severity="error"
            open={esnack}
            close={handleEClose}
            data="Transaction failed. Payments not confirmed"
          />
        )}
        {isCode ? (
          <>
            {!data.amounts ? (
              <Box className={classes.paymentBox}>
                {!data.fixAmt && (
                  <Box style={{ display: fdisplay ? 'block' : 'none' }}>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input-address"
                      style={{
                        fontWeight: 600,
                        fontSize: 17,
                        marginTop: 10,
                        letterSpacing: 1
                      }}
                    >
                      <Typography variant="p">Amount</Typography>
                    </InputLabel>
                    <BootstrapInput
                      fullWidth
                      placeholder="Amount"
                      margin="normal"
                      name="amount"
                      type="number"
                      id="bootstrap-input-amount"
                      required
                      value={inputs?.amount}
                      onChange={handleChange}
                      style={{ marginRight: 8 }}
                    />
                  </Box>
                )}
                <Box style={{ marginBottom: 15 }}>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input-address"
                    style={{
                      fontWeight: 600,
                      fontSize: 17,
                      marginTop: 10,
                      letterSpacing: 1
                    }}
                  >
                    <Typography variant="p">Payment number</Typography>
                  </InputLabel>
                  <BootstrapInput
                    fullWidth
                    placeholder="ex: 6xxxxxxxx"
                    margin="normal"
                    name="phone"
                    type="number"
                    id="bootstrap-input-phone"
                    value={inputs?.phone}
                    onChange={handleChange}
                    required
                    style={{ marginRight: 8, marginBottom: -15 }}
                  />
                </Box>
                <Button
                  color="primary"
                  style={{ textTransform: 'none', marginTop: 10 }}
                  variant="contained"
                  fullWidth
                  className={classes.button2}
                  onClick={handleMomo}
                  disabled={isLoadingOne}
                >
                  {isLoadingOne ? (
                    <CircularProgress
                      className={classes.circularProgress}
                      size="14px"
                    />
                  ) : (
                    'Pay with Momo or OM'
                  )}{' '}
                </Button>
              </Box>
            ) : (
              <Box className={classes.paymentBox}>
                <Box style={{ marginBottom: 15 }}>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input-address"
                    style={{
                      fontWeight: 600,
                      fontSize: 17,
                      marginTop: 10,
                      letterSpacing: 1
                    }}
                  >
                    <Typography variant="p">Payment number</Typography>
                  </InputLabel>
                  <BootstrapInput
                    fullWidth
                    placeholder="ex: 6xxxxxxxx"
                    margin="normal"
                    name="phone"
                    type="number"
                    id="bootstrap-input-phone"
                    value={inputs?.phone}
                    onChange={handleChange}
                    required
                    style={{ marginRight: 8, marginBottom: -15 }}
                  />
                </Box>
                <Button
                  color="primary"
                  style={{ textTransform: 'none', marginTop: 10 }}
                  variant="contained"
                  fullWidth
                  className={classes.button2}
                  onClick={handleQuantityMomo}
                  disabled={isLoadingOne}
                >
                  {isLoadingOne ? (
                    <CircularProgress
                      className={classes.circularProgress}
                      size="14px"
                    />
                  ) : (
                    'Pay with Momo or OM'
                  )}{' '}
                </Button>
              </Box>
            )}
          </>
        ) : (
          <>
            {errorThree && (
              <Typography
                variant="p"
                style={{
                  color: '#eb6558',
                  fontWeight: 900,
                  fontSize: 15
                }}
              >
                {eresponseThree}
              </Typography>
            )}
            <Box style={{ marginBottom: 5 }}>
              <Typography
                style={{
                  fontSize: 14,
                  fontFamily: 'Ubuntu',
                  color: '#00000099'
                }}
              >
                An SMS code has been sent to your phone number. Please fill in
                this code.
              </Typography>
              <BootstrapInput
                fullWidth
                placeholder="Enter code"
                margin="normal"
                name="code"
                type="text"
                id="bootstrap-input-qty"
                value={code}
                onChange={e => setCode(e.target.value)}
                required
                style={{ marginRight: 8 }}
              />
              <Button onClick={handleCancel} className={classes.cancelBtn}>
                Cancel transaction
              </Button>
            </Box>
            <Button
              color="primary"
              style={{ textTransform: 'none', marginTop: 5 }}
              variant="contained"
              fullWidth
              className={classes.button2}
              onClick={handleSMS}
              disabled={isLoadingThree}
            >
              {isLoadingThree ? (
                <CircularProgress
                  className={classes.circularProgress}
                  size="14px"
                />
              ) : (
                'Confirm'
              )}{' '}
            </Button>
          </>
        )}
      </Box>
    );
  };
  const navigate = useNavigate();
  return (
    <Page className={classes.introtitle} title="view product">
      <Box className={classes.root}>
        <>
          {!data.amounts ? (
            <Container>
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignContent: 'center'
                }}
                container
                spacing={3}
              >
                <Grid item xs={12} sm={12} lg={6} xl={6}>
                  <Box className={classes.mainBox}>
                    {data.fixAmt && (
                      <Box>
                        <Typography variant="h1" className={classes.text}>
                          <span
                            style={{
                              color: '#1a1a1adb',
                              fontWeight: 400,
                              marginTop: 10
                            }}
                          >
                            FCFA
                          </span>
                          {data.fixAmt}
                        </Typography>
                      </Box>
                    )}
                    <Box className={classes.textBox}>
                      {!load ? (
                        <Typography className={classes.text} variant="h5">
                          {data.organisation}
                        </Typography>
                      ) : (
                        <Skeleton variant="text" width={150} height={30} />
                      )}
                    </Box>
                    <Box style={{ marginBottom: 20 }}>
                      {!load ? (
                        <Typography
                          style={{
                            color: '#00000099',
                            fontWeight: 500,
                            fontSize: 16,
                            fontFamily: 'Ubuntu'
                          }}
                          className={classes.text}
                        >
                          {data.description}
                        </Typography>
                      ) : (
                        <Skeleton variant="text" width={150} height={100} />
                      )}
                    </Box>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input-address"
                      style={{
                        color: '#1a1a1ab3',
                        fontWeight: 600,
                        fontSize: 20,
                        letterSpacing: 1
                      }}
                    ></InputLabel>
                    {errorOne && (
                      <Box
                        style={{
                          marginTop: 10
                        }}
                      >
                        <Typography
                          variant="p"
                          style={{
                            color: '#eb6558',
                            fontWeight: 900,
                            fontSize: 16
                          }}
                        >
                          {eresponseOne}
                        </Typography>
                      </Box>
                    )}
                    {cardError && (
                      <Box
                        style={{
                          marginTop: 10
                        }}
                      >
                        <Typography
                          variant="p"
                          style={{
                            color: '#eb6558',
                            fontWeight: 900,
                            fontSize: 16
                          }}
                        >
                          {cardResponseError}
                        </Typography>
                      </Box>
                    )}
                    {isCode && (
                      <>
                        <InputLabel
                          shrink
                          htmlFor="bootstrap-input-address"
                          style={{
                            fontWeight: 600,
                            fontSize: 17,
                            marginTop: 10,
                            letterSpacing: 1
                          }}
                        >
                          <Typography variant="p">Full name</Typography>
                        </InputLabel>
                        <BootstrapInput
                          fullWidth
                          placeholder="name"
                          margin="normal"
                          name="clientName"
                          type="text"
                          id="bootstrap-input-name"
                          required
                          autoFocus
                          style={{ marginRight: 8 }}
                          value={inputs?.clientName}
                          onChange={handleChange}
                        />
                        <InputLabel
                          shrink
                          htmlFor="bootstrap-input-address"
                          style={{
                            fontWeight: 600,
                            fontSize: 17,
                            marginTop: 10,
                            letterSpacing: 1
                          }}
                        >
                          <Typography variant="p">Email address</Typography>
                        </InputLabel>
                        <BootstrapInput
                          fullWidth
                          placeholder="email"
                          margin="normal"
                          name="email"
                          type="text"
                          id="bootstrap-input-email"
                          required
                          style={{ marginRight: 8 }}
                          value={inputs?.email}
                          onChange={handleChange}
                        />
                      </>
                    )}
                    <Box className={classes.boxOne}>
                      <Box>{PaymentsDisplay()}</Box>
                    </Box>
                    {data.cardAllowed && (
                      <Box className={classes.paymentBox}>
                        <Box
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: 10,
                            marginBottom: 10
                          }}
                        >
                          <Divider
                            style={{
                              height: 1,
                              width: 100,
                              marginRight: 10,
                              backgroundColor: '#d6d7dfe3'
                            }}
                          />
                          <Typography>OR</Typography>
                          <Divider
                            style={{
                              height: 1,
                              width: 100,
                              marginLeft: 10,
                              backgroundColor: '#d6d7dfe3'
                            }}
                          />
                        </Box>
                        <Button
                          onClick={handleCardSubmit}
                          color="primary"
                          variant="contained"
                          className={classes.cardButton}
                        >
                          {isCardButtonLoading ? (
                            <CircularProgress
                              className={classes.circularProgress}
                              size="14px"
                            />
                          ) : (
                            'Pay with Card'
                          )}{' '}
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} lg={6} xl={6}>
                  <Table data={data.donationList} />
                </Grid>
              </Grid>
            </Container>
          ) : (
            <Grid
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignContent: 'center'
              }}
              container
              spacing={2}
            >
              <Grid item xs={12} sm={12} lg={4} xl={4}>
                <Box className={classes.firstBox}>
                  <Box className={classes.textBox}>
                    <Typography className={classes.text} variant="h5">
                      {data.organisation}
                    </Typography>
                  </Box>
                  <Box style={{ marginBottom: 20 }}>
                    <Typography
                      style={{
                        color: '#00000099',
                        fontWeight: 500,
                        fontSize: 16,
                        fontFamily: 'Ubuntu'
                      }}
                      className={classes.text}
                    >
                      {data.description}
                    </Typography>
                  </Box>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input-address"
                    style={{
                      color: '#1a1a1ab3',
                      fontWeight: 600,
                      fontSize: 22,
                      letterSpacing: 1,
                      fontFamily: 'Ubuntu'
                    }}
                  >
                    <Typography variant="p">Quantity</Typography>
                  </InputLabel>
                  <BootstrapInput
                    fullWidth
                    placeholder="name"
                    margin="normal"
                    name="clientName"
                    type="number"
                    id="bootstrap-input-name"
                    required
                    autoFocus
                    value={quantity}
                    style={{ width: '40%', marginRight: 8 }}
                    onChange={e => {
                      setQuantity(e.target.value);
                    }}
                  />
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input-address"
                    style={{
                      color: '#1a1a1ab3',
                      fontWeight: 600,
                      fontSize: 22,
                      letterSpacing: 1,
                      fontFamily: 'Ubuntu',
                      marginTop: 10
                    }}
                  >
                    Please select a price option
                  </InputLabel>
                  <FormControl>
                    <Box style={{ display: 'block' }}>
                      <RadioGroup
                        aria-labelledby="demo-customized-radios"
                        name="customized-radios"
                      >
                        {data.amounts.map(items => {
                          return (
                            <FormControlLabel
                              value={items.amount}
                              control={<BpRadio />}
                              label={
                                <Typography variant="p">
                                  {`${items.desc} (FCFA${items.amount})`}
                                </Typography>
                              }
                              onChange={e => {
                                setVariableAmount(e.target.value);
                              }}
                            />
                          );
                        })}
                      </RadioGroup>
                    </Box>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} lg={4} xl={4}>
                <Box className={classes.mainBox}>
                  <Box>
                    <Typography variant="h1" className={classes.text}>
                      <span
                        style={{
                          color: '#1a1a1adb',
                          fontWeight: 400,
                          marginTop: 10
                        }}
                      >
                        FCFA
                      </span>
                      {variableAmount * quantity}
                    </Typography>
                  </Box>
                  {errorOne && (
                    <Box
                      style={{
                        marginTop: 10
                      }}
                    >
                      <Typography
                        variant="p"
                        style={{
                          color: '#eb6558',
                          fontWeight: 900,
                          fontSize: 16
                        }}
                      >
                        {eresponseOne}
                      </Typography>
                    </Box>
                  )}
                  {cardError && (
                    <Box
                      style={{
                        marginTop: 10
                      }}
                    >
                      <Typography
                        variant="p"
                        style={{
                          color: '#eb6558',
                          fontWeight: 900,
                          fontSize: 16
                        }}
                      >
                        {cardResponseError}
                      </Typography>
                    </Box>
                  )}
                  {isCode && (
                    <>
                      <InputLabel
                        shrink
                        htmlFor="bootstrap-input-address"
                        style={{
                          fontWeight: 600,
                          fontSize: 17,
                          marginTop: 10,
                          letterSpacing: 1
                        }}
                      >
                        <Typography variant="p">Full name</Typography>
                      </InputLabel>
                      <BootstrapInput
                        fullWidth
                        placeholder="name"
                        margin="normal"
                        name="clientName"
                        type="text"
                        id="bootstrap-input-name"
                        required
                        style={{ marginRight: 8 }}
                        value={inputs?.clientName}
                        onChange={handleChange}
                      />
                      <InputLabel
                        shrink
                        htmlFor="bootstrap-input-address"
                        style={{
                          fontWeight: 600,
                          fontSize: 17,
                          marginTop: 10,
                          letterSpacing: 1
                        }}
                      >
                        <Typography variant="p">Email address</Typography>
                      </InputLabel>
                      <BootstrapInput
                        fullWidth
                        placeholder="email"
                        margin="normal"
                        name="email"
                        type="text"
                        id="bootstrap-input-email"
                        required
                        style={{ marginRight: 8 }}
                        value={inputs?.email}
                        onChange={handleChange}
                      />
                    </>
                  )}
                  <Box className={classes.boxOne}>
                    <Box>{PaymentsDisplay()}</Box>
                  </Box>
                  {data.cardAllowed && (
                    <Box className={classes.paymentBox}>
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginTop: 10,
                          marginBottom: 10
                        }}
                      >
                        <Divider
                          style={{
                            height: 1,
                            width: 100,
                            marginRight: 10,
                            backgroundColor: '#d6d7dfe3'
                          }}
                        />
                        <Typography>OR</Typography>
                        <Divider
                          style={{
                            height: 1,
                            width: 100,
                            marginLeft: 10,
                            backgroundColor: '#d6d7dfe3'
                          }}
                        />
                      </Box>
                      <Button
                        onClick={handleCardSubmit}
                        color="primary"
                        variant="contained"
                        className={classes.cardButton}
                      >
                        {isCardButtonLoading ? (
                          <CircularProgress
                            className={classes.circularProgress}
                            size="14px"
                          />
                        ) : (
                          'Pay with Card'
                        )}{' '}
                      </Button>
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} lg={4} xl={4}>
                <Table data={data.donationList} />
              </Grid>
            </Grid>
          )}
        </>
      </Box>
      <Footer />
    </Page>
  );
};

export default LinkView;
