import React from 'react';
import { Typography } from '@material-ui/core';

const Footer = () => {
  return (
    <div>
      <Typography
        style={{
          bottom: 20,
          textAlign: 'center',
          fontFamily: 'Ubuntu',
          fontSize: 14,
          left: 0,
          width: '100%',
          color: '#72747c',
          position: 'absolute'
        }}
      >
        {' '}
        Powered by{' '}
        <span style={{ fontWeight: 'bold', fontSize: 16 }}>
          Fapshi, Inc.
        </span> |{' '}
        <a
          style={{
            color: '#72747c',
            marginRight: 5,
            textDecoration: 'underline'
          }}
          href="https://fapshi.com"
          target="_blank"
        >
          Home
        </a>
        <a
          style={{
            color: '#72747c',
            marginRight: 5,
            textDecoration: 'underline'
          }}
          href="https://dashboard.fapshi.com"
          target="_blank"
        >
          Register
        </a>
        <a
          style={{ color: '#72747c', textDecoration: 'underline' }}
          href="https://fapshi.com/privacy-policy"
          target="_blank"
        >
          Policy
        </a>
      </Typography>
    </div>
  );
};

export default Footer;
