import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars({
  open,
  data,
  close,
  duration,
  severity
}) {
  const handleAlertClose = () => {
    close();
  };
  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={open} autoHideDuration={duration} onClose={close}>
        <Alert
          onClose={handleAlertClose}
          severity={severity}
          sx={{ width: '100%' }}
        >
          {data}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
