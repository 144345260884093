const cart = localStorage.getItem('cart')
  ? JSON.parse(localStorage.getItem('cart'))
  : [];

console.log(cart);

export const initialState = {
  cart: cart || [],
  loading: false,
  errorMessage: null
};

export const CartReducer = (initialState, action) => {
  switch (action.type) {
    case 'CART_ADDED':
      return {
        ...initialState,
        cart: action.payload,
        loading: true
      };
    case 'CART_UPDATED':
      return {
        ...initialState,
        cart: action.payload,
        loading: false
      };
    case 'CART_CLEARED':
      return {
        ...initialState,
        cart: []
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
