import React, { useState, useEffect } from 'react';
import { Grid, Container, Box } from '@material-ui/core';
import ServiceList from './ServiceList';
import Footer from 'src/components/Footer';
import { FaCheckCircle } from 'react-icons/fa';
import { makeStyles, Typography } from '@material-ui/core';
import { useAuthState } from 'src/context';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  box: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(25),
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'Center',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(5),
      width: '100%',
      maxWidth: '100%'
    }
  },
  icons: {
    fontSize: '3rem',
    color: 'green'
  },
  heading: {
    textAlign: 'center'
  },
  body: {
    color: '#2b2c2e',
    textAlign: 'center',
    lineHeight: 1.3,
    marginTop: theme.spacing(2)
  },
  link: {
    marginTop: theme.spacing(2),
    textDecoration: 'underline',
    color: '#000'
  }
}));

export const Success = () => {
  const classes = useStyles();
  const { token } = useAuthState();
  const params = useParams();
  const [data, setData] = useState([]);
  const id = params?.id;

  useEffect(() => {
    const fetchProductData = async id => {
      await axios({
        method: 'get',
        url: 'https://api.fapshi.com/transPayDetails/' + id,
        headers: { 'x-access-token': token }
      })
        .then(res => {
          setData(res.data);
          if (res.data.redirectUrl) {
            setTimeout(function() {
              window.location.assign(res.data.redirectUrl);
            }, 5000);
          }
        })
        .catch(error => {
          console.log(error);
          console.log(data);
        });
    };
    fetchProductData(id);
  }, []);

  const switchType = () => {
    if (data.productName === 'Donation') {
      return (
        <Box className={classes.box}>
          <FaCheckCircle
            className={classes.icons}
            color="success"
            fontSize="20px"
          />
          <Typography className={classes.heading} variant="h5">
            Transaction successful
          </Typography>
          <Typography variant="p" className={classes.body}>
            Your payment of <b>FCFA{data.amount}</b> to{' '}
            <b>{data.organisation}</b> was successful. Please check your email
            for details of the transaction and payment receipt.
          </Typography>
          <a style={{ marginTop: 20 }} href={'/link/' + data.donationId}>
            <span className={classes.link}>Return to payments page</span>
          </a>
        </Box>
      );
    } else if (data.productName === 'Payment link') {
      return (
        <Box className={classes.box}>
          <FaCheckCircle
            className={classes.icons}
            color="success"
            fontSize="20px"
          />
          <Typography className={classes.heading} variant="h5">
            Transaction successful
          </Typography>
          <Typography variant="p" className={classes.body}>
            Your payment of <b>FCFA{data.amount}</b> to{' '}
            <b>{data.organisation}</b> was successful. Please check your email
            for details of the transaction and payment receipt.
          </Typography>
          <a style={{ marginTop: 20 }} href={'/link/' + data.donationId}>
            <span className={classes.link}>Return to payments page</span>
          </a>
        </Box>
      );
    } else if (data.serviceName) {
      return (
        <Box className={classes.box}>
          <FaCheckCircle
            className={classes.icons}
            color="success"
            fontSize="20px"
          />
          <Typography className={classes.heading} variant="h5">
            Transaction successful
          </Typography>
          <Typography variant="p" className={classes.body}>
            Your payment of <b>FCFA{data.amount}</b> to{' '}
            <b>{data.serviceName}</b> was successful. Please check your email
            for details of the transaction and payment receipt.
          </Typography>
          {data.redirectUrl && (
            <a style={{ marginTop: 20 }} href={data.redirectUrl}>
              <span className={classes.link}>Return to home page</span>
            </a>
          )}
        </Box>
      );
    } else if (data.productName === 'Invoice') {
      return (
        <Box className={classes.box}>
          <FaCheckCircle
            className={classes.icons}
            color="success"
            fontSize="20px"
          />
          <Typography className={classes.heading} variant="h5">
            Transaction successful
          </Typography>
          <Typography variant="p" className={classes.body}>
            Your payment of <b>FCFA{data.total}</b> to <b>{data.vendorName}</b>{' '}
            was successful. Please check your email for details of the
            transaction and payment receipt.
          </Typography>
          <a style={{ marginTop: 20 }} href={'/invoices/' + data.getCode}>
            <span className={classes.link}>Return to payments page</span>
          </a>
        </Box>
      );
    } else {
      return (
        <Box className={classes.box}>
          <FaCheckCircle
            className={classes.icons}
            color="success"
            fontSize="20px"
          />
          <Typography className={classes.heading} variant="h5">
            Transaction successful
          </Typography>
          <Typography variant="p" className={classes.body}>
            Your payment of <b>FCFA{data.total}</b> to <b>{data.vendorName}</b>{' '}
            for <b>{data.quantity}</b> units of <b>{data.productName}</b> was
            successful. Please check your email for details of the transaction
            and payment receipt.
          </Typography>
          <a style={{ marginTop: 20 }} href={'/products/' + data.productId}>
            <span className={classes.link}>Return to payments page</span>
          </a>
        </Box>
      );
    }
  };
  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} lg={6} xl={6}>
          {switchType()}
        </Grid>
        <Grid item xs={12} sm={12} lg={6} xl={6}>
          <ServiceList />
        </Grid>
      </Grid>
      <Footer />
    </Container>
  );
};

export default Success;
