import React, { useState, useEffect } from 'react';
import Page from 'src/components/Page';
import { Box, Typography, Button, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useAuthState } from 'src/context';
import axios from 'axios';
import { Image } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import Footer from 'src/components/Footer';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from 'src/components/Snackbar';
import ServiceList from './ServiceList';

const useStyles = makeStyles(theme => ({
  introtitle: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontSize: 22,
    fontWeight: 600,
    color: '#1a1f36'
  },
  inputfield: {
    width: '100%'
  },
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  button: {
    display: 'block',
    margin: '10px 0px 10px 0px',
    width: 200
  },
  mainBox: {
    padding: '10px',
    borderRadius: '5px',
    width: '350px',
    marginLeft: 20,
    marginTop: 15,
    [theme.breakpoints.down('md')]: {
      width: 'auto',
      marginRight: '1.5rem'
    }
  },

  pIcons: {
    color: '#8b8b95',
    height: 30,
    width: 30,
    marginTop: 15
  },
  buttons: {
    backgroundColor: '#ffffff',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
    border: '1px solid #ced4da',
    borderRadius: 20,
    height: 25,
    marginRight: 4,
    '&:hover': {
      color: 'black',
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
      border: '1px solid #ffffff'
    }
  },
  boxTwo: {
    marginTop: 10,
    width: '400px',
    borderRadius: 4,
    padding: 25,
    zIndex: 1000,
    [theme.breakpoints.down('md')]: {
      margin: '0px 0px 20px 0px',
      width: 'auto',
      borderRadius: 4,
      padding: 25,
      zIndex: 1000
    }
  },
  gridOne: {
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      border: 'none'
    }
  },
  button2: {
    height: 45,
    borderRadius: 8,
    backgroundColor: '#000000e3',
    '&:hover': {
      backgroundColor: '#000000e3',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
      color: '#fff'
    }
  },
  cardButton: {
    height: 45,
    color: '#fff',
    width: 335,
    borderRadius: 8,
    textTransform: 'capitalize',
    backgroundColor: '#000000e3',
    '&:hover': {
      backgroundColor: '#000000e3',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
      color: '#fff'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  toolbar: {
    boxShadow: 'none',
    minHeight: 40,
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'space-between',
    backgroundColor: '#ffffff',
    borderBottom: '1px solid rgb(234 235 255)',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0
    }
  },
  cancelBtn: {
    fontSize: 14,
    textTransform: 'none',
    color: '#c10e0e',
    textDecoration: 'underline',
    margin: 0,
    padding: 0,
    '&:hover': {
      backgroundColor: '#fff'
    }
  },
  gallery: {
    border: '1px solid rgb(228 230 239)',
    width: 45,
    height: 45,
    objectFit: 'cover',
    marginRight: 5,
    cursor: 'pointer'
  },
  image: {
    border: '1px solid rgb(228 230 239)',
    width: 200,
    height: 200,
    objectFit: 'cover',
    marginTop: 5
  },
  timage: {
    border: '1px solid rgb(228 230 239)',
    width: 300,
    height: 300,
    objectFit: 'cover',
    marginTop: 5,
    [theme.breakpoints.down('md')]: {
      width: 200,
      height: 200
    }
  },
  talentImage: {
    width: 500,
    height: 500,
    objectFit: 'cover',
    marginTop: 5,
    [theme.breakpoints.down('md')]: {
      marginTop: -20,
      width: 'auto',
      marginLeft: -100,
      height: 500
    }
  },
  galleryBox: {
    display: 'inline-flex',
    marginTop: 10
  },
  displayBox: {
    width: 300,
    padding: 20,
    marginLeft: 10,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      margin: 'auto'
    }
  },
  talentBox: {
    width: 400,
    padding: 20,
    marginLeft: 200,
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      margin: 'auto'
    }
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1.5px solid #d6d8dd',
    fontSize: 16,
    width: '100%',
    height: '20px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    borderRadius: 7,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    marginTop: 0,
    // Use the system font instead of the default Roboto font.
    fontFamily: ['Ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#a7aecf',
      borderRadius: 10
    },
    '&:hover': {
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      // borderColor: '#e3e8fd',
      borderRadius: 10
    }
  }
}));

const ViewProductWithId = () => {
  const classes = useStyles();

  const { token } = useAuthState();

  const params = useParams();

  const [data, setData] = useState([]);

  const [quantity, setQuantity] = useState([]);

  const [snack, setSnack] = useState(false);
  const [esnack, setESnack] = useState(false);

  const [errorOne, setErrorOne] = useState(false);
  const [eresponseOne, setEresponseOne] = useState(null);
  const [isLoadingOne, setIsLoadingOne] = useState(false);

  const [errorThree, setErrorThree] = useState(false);
  const [eresponseThree, setEresponseThree] = useState(null);
  const [isLoadingThree, setIsLoadingThree] = useState(false);

  const [displayImage, setDisplayImage] = useState(null);

  const [cardError, setCardError] = useState(false);
  const [cardResponseError, setCardResponseError] = useState(null);

  const handleQuantityChange = event => {
    setQuantity(event.target.value);
  };

  useEffect(() => {
    const id = params?.id;
    const fetchProductData = async id => {
      await axios({
        method: 'get',
        url: 'https://api.fapshi.com/product/' + id,
        headers: { 'x-access-token': token }
      })
        .then(res => {
          setData(res.data);
          setDisplayImage(res.data.productImage);
        })
        .catch(error => {
          console.log(error);
          navigate('/', { replace: true });
        });
    };
    fetchProductData(id);
  }, []);

  const [isCode, setIscode] = useState(true);
  const [code, setCode] = useState(null);
  const [isCardButtonLoading, setIsCardButtonLoading] = useState(false);

  const [inputs, setInputs] = useState({
    vendorName: null,
    quantity: null,
    clientName: null,
    email: null,
    phone: null
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setInputs({ ...inputs, [name]: value });
  };

  const handleMomo = () => {
    setIsLoadingOne(true);
    setEresponseOne(null);
    const values = {
      productId: data.productId,
      vendorName: data.vendorName,
      quantity: parseInt(quantity),
      clientName: inputs.clientName,
      email: inputs.email,
      phone: inputs.phone
    };
    axios({
      method: 'post',
      url: 'https://api.fapshi.com/product/pay/momo',
      data: values
    })
      .then(() => {
        setIscode(false);
        setIsLoadingOne(false);
      })
      .catch(err => {
        if (err.response.status === 402) {
          axios({
            method: 'get',
            url:
              'https://api.fapshi.com/transaction/product/' +
              err.response.data.uuid
          })
            .then(res => {
              console.log(res.data);
              if (res.data.status === 'SUCCESSFUL') {
                setSnack(true);
                navigate('/success/' + res.data.transferId, { replace: true });
              } else if (res.data.status === 'FAILED') {
                setESnack(true);
              }
              setIscode(true);
              setInputs(' ');
              setIsLoadingOne(false);
              setCode(null);
            })
            .catch(() => {
              setIsLoadingOne(false);
              setCode(null);
            });
        } else {
          setIsLoadingOne(false);
        }
        setErrorOne(true);
        setEresponseOne(err.response?.data.message);
      });
  };

  const handleSMS = () => {
    setIsLoadingThree(true);
    const values = {
      code,
      phone: inputs.phone
    };
    axios({
      method: 'post',
      url: 'https://api.fapshi.com/product/confirmsmscode',
      data: values
    })
      .then(res => {
        axios({
          method: 'get',
          url: 'https://api.fapshi.com/transaction/product/' + res.data.uuid
        })
          .then(res => {
            console.log(res.data);
            if (res.data.status === 'SUCCESSFUL') {
              setSnack(true);
              navigate('/success/' + res.data.transferId, { replace: true });
            } else if (res.data.status === 'FAILED') {
              setESnack(true);
            }
            setIscode(true);
            setInputs(' ');
            setIsLoadingThree(false);
            setCode(null);
          })
          .catch(() => {
            setIsLoadingThree(false);
            setEresponseThree(null);
            setCode(null);
          });
      })
      .catch(err => {
        setIsLoadingThree(false);
        setErrorThree(true);
        setEresponseThree(err.response?.data.message);
      });
  };
  const handleCardSubmit = () => {
    setIsCardButtonLoading(true);
    const values = {
      productId: data.productId,
      clientName: inputs.clientName,
      email: inputs.email,
      vendorName: data.vendorName,
      quantity: parseInt(quantity)
    };
    axios({
      method: 'post',
      url: 'https://api.fapshi.com/product/pay/card/',
      data: values
    })
      .then(res => {
        window.location.assign(res.data.link);
      })
      .catch(err => {
        setIsCardButtonLoading(false);
        setCardError(true);
        setCardResponseError(err.response?.data.message);
      });
  };
  const handleCancel = () => {
    setIscode(true);
    setIsLoadingThree(false);
    setCode(null);
    setInputs(null);
    setEresponseOne(null);
    setEresponseThree(null);
  };

  const handleClose = () => {
    setSnack(false);
  };
  const handleEClose = () => {
    setESnack(false);
  };

  const handleImageSwitch = img => {
    setDisplayImage(img);
  };

  const PaymentsDisplay = () => {
    return (
      <Box className={classes.paymentBox}>
        {snack && (
          <Snackbar
            duration={6000}
            open={snack}
            close={handleClose}
            severity="success"
            data="Payments completed successfully"
          />
        )}
        {esnack && (
          <Snackbar
            duration={6000}
            severity="error"
            open={esnack}
            close={handleEClose}
            data="Transaction failed. Payments not confirmed"
          />
        )}
        {isCode ? (
          <>
            <Box className={classes.paymentBox}>
              <Box style={{ marginBottom: 15 }}>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-address"
                  style={{
                    fontWeight: 600,
                    fontSize: 17,
                    marginTop: 10,
                    letterSpacing: 1
                  }}
                >
                  <Typography variant="p">Payment number</Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="ex: 6xxxxxxxx"
                  margin="normal"
                  name="phone"
                  type="number"
                  id="bootstrap-input-phone"
                  value={inputs?.phone}
                  onChange={handleChange}
                  required
                  style={{ marginRight: 8, marginBottom: -15 }}
                />
              </Box>
              <Button
                color="primary"
                style={{ textTransform: 'none', marginTop: 10 }}
                variant="contained"
                fullWidth
                className={classes.button2}
                onClick={handleMomo}
                disabled={isLoadingOne}
              >
                {isLoadingOne ? (
                  <CircularProgress
                    className={classes.circularProgress}
                    size="14px"
                  />
                ) : (
                  'Pay with Momo Or OM'
                )}{' '}
              </Button>
            </Box>
          </>
        ) : (
          <>
            {errorThree && (
              <Typography
                variant="p"
                style={{
                  color: '#eb6558',
                  fontWeight: 900,
                  fontSize: 15
                }}
              >
                {eresponseThree}
              </Typography>
            )}
            <Box style={{ marginBottom: 5 }}>
              <Typography
                style={{
                  fontSize: 14,
                  fontFamily: 'Ubuntu',
                  color: '#00000099'
                }}
              >
                An SMS code has been sent to your phone number. Please fill in
                this code.
              </Typography>
              <BootstrapInput
                fullWidth
                placeholder="Enter code"
                margin="normal"
                name="code"
                type="text"
                id="bootstrap-input-qty"
                value={code}
                onChange={e => setCode(e.target.value)}
                required
                style={{ marginRight: 8 }}
              />
              <Button onClick={handleCancel} className={classes.cancelBtn}>
                Cancel transaction
              </Button>
            </Box>
            <Button
              color="primary"
              style={{ textTransform: 'none', marginTop: 5 }}
              variant="contained"
              fullWidth
              className={classes.button2}
              onClick={handleSMS}
              disabled={isLoadingThree}
            >
              {isLoadingThree ? (
                <CircularProgress
                  className={classes.circularProgress}
                  size="14px"
                />
              ) : (
                'Confirm'
              )}{' '}
            </Button>
          </>
        )}
      </Box>
    );
  };

  const navigate = useNavigate();

  return (
    <Page className={classes.introtitle} title="view product">
      <Box className={classes.root}>
        {data.isVote ? (
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'center'
            }}
            container
            spacing={3}
          >
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Box className={classes.talentBox}>
                <Typography
                  style={{
                    fontWeight: '900',
                    fontSize: 26,
                    fontFamily: 'Ubuntu',
                    color: '#2b2c2e'
                  }}
                >
                  {data.vendorName}
                </Typography>
                <Box>
                  <Image
                    disabled
                    preview={false}
                    className={classes.timage}
                    src={displayImage}
                  />
                </Box>
                <Typography
                  style={{
                    fontWeight: '900',
                    fontSize: 30,
                    fontFamily: 'Ubuntu',
                    color: '#2b2c2e'
                  }}
                >
                  {data.productName}{' '}
                  <span
                    style={{ color: '#525356', fontSize: 22 }}
                  >{`(${data.qtySold} votes)`}</span>
                </Typography>
                <Typography
                  style={{
                    fontWeight: '700',
                    fontSize: 22,
                    color: '#2b2c2e',
                    fontFamily: 'Ubuntu'
                  }}
                  // variant="p"
                >
                  {data.description}
                </Typography>
                <Box style={{ marginTop: 20 }}>
                  <Typography variant="p" style={{ fontSize: 22 }}>
                    Price of a vote: FCFA{data.price}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <Box className={classes.mainBox}>
                {errorOne && (
                  <Box
                    style={{
                      marginTop: 10
                    }}
                  >
                    <Typography
                      variant="p"
                      style={{
                        color: '#eb6558',
                        fontWeight: 900,
                        fontSize: 16
                      }}
                    >
                      {eresponseOne}
                    </Typography>
                  </Box>
                )}
                {cardError && (
                  <Box
                    style={{
                      marginTop: 10
                    }}
                  >
                    <Typography
                      variant="p"
                      style={{
                        color: '#eb6558',
                        fontWeight: 900,
                        fontSize: 16
                      }}
                    >
                      {cardResponseError}
                    </Typography>
                  </Box>
                )}
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-address"
                  style={{
                    fontWeight: 600,
                    fontSize: 17,
                    marginTop: 10,
                    letterSpacing: 1
                  }}
                >
                  <Typography variant="p">Number of votes</Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Quantity"
                  margin="normal"
                  name="quantity"
                  type="number"
                  id="bootstrap-input-name"
                  required
                  autoFocus
                  value={quantity}
                  style={{ marginRight: 8, marginBottom: 10 }}
                  onChange={handleQuantityChange}
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-address"
                  style={{
                    color: '#1a1a1ab3',
                    fontWeight: 600,
                    fontSize: 20,
                    letterSpacing: 1
                  }}
                ></InputLabel>
                {isCode && (
                  <>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input-address"
                      style={{
                        fontWeight: 600,
                        fontSize: 17,
                        marginTop: 10,
                        letterSpacing: 1
                      }}
                    >
                      <Typography variant="p">Full name</Typography>
                    </InputLabel>
                    <BootstrapInput
                      fullWidth
                      placeholder="name"
                      margin="normal"
                      name="clientName"
                      type="text"
                      id="bootstrap-input-name"
                      required
                      style={{ marginRight: 8 }}
                      value={inputs?.clientName}
                      onChange={handleChange}
                    />
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input-address"
                      style={{
                        fontWeight: 600,
                        fontSize: 17,
                        marginTop: 10,
                        letterSpacing: 1
                      }}
                    >
                      <Typography variant="p">
                        Email address (Optional)
                      </Typography>
                    </InputLabel>
                    <BootstrapInput
                      fullWidth
                      placeholder="email"
                      margin="normal"
                      name="email"
                      type="text"
                      id="bootstrap-input-email"
                      required
                      style={{ marginRight: 8 }}
                      value={inputs?.email}
                      onChange={handleChange}
                    />
                  </>
                )}
                <Box className={classes.boxOne}>
                  <Box>{PaymentsDisplay()}</Box>
                </Box>
                {data.cardAllowed && (
                  <Box className={classes.paymentBox}>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: 10,
                        marginBottom: 10
                      }}
                    >
                      <Divider
                        style={{
                          height: 1,
                          width: 100,
                          marginRight: 10,
                          backgroundColor: '#d6d7dfe3'
                        }}
                      />
                      <Typography>OR</Typography>
                      <Divider
                        style={{
                          height: 1,
                          width: 100,
                          marginLeft: 10,
                          backgroundColor: '#d6d7dfe3'
                        }}
                      />
                    </Box>
                    <Button
                      onClick={handleCardSubmit}
                      color="primary"
                      variant="contained"
                      className={classes.cardButton}
                    >
                      {isCardButtonLoading ? (
                        <CircularProgress
                          className={classes.circularProgress}
                          size="14px"
                        />
                      ) : (
                        'Pay with Card'
                      )}{' '}
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'center'
            }}
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              sm={12}
              lg={4}
              xl={4}
              className={classes.gridOne}
            >
              <Box className={classes.displayBox}>
                <Typography
                  style={{
                    fontWeight: '900',
                    fontSize: 24,
                    fontFamily: 'Ubuntu',
                    color: '#2b2c2e'
                  }}
                >
                  {data.vendorName}
                </Typography>
                {data.gallery && (
                  <Box className={classes.galleryBox}>
                    <Image
                      disabled
                      preview={false}
                      className={classes.gallery}
                      onClick={() => handleImageSwitch(data.productImage)}
                      src={data.productImage}
                    />
                    {data.gallery.map(image => (
                      <Image
                        disabled
                        preview={false}
                        className={classes.gallery}
                        onClick={() => handleImageSwitch(image)}
                        src={image}
                      />
                    ))}
                  </Box>
                )}
                <Box>
                  <Image
                    disabled
                    preview={false}
                    className={classes.image}
                    src={displayImage}
                  />
                </Box>
                <Typography
                  variant="p"
                  style={{ color: '#2b2c2e', fontSize: 14, fontWeight: 500 }}
                >
                  {data.productName}
                </Typography>
                <br />
                <Typography variant="p" style={{ fontSize: 38 }}>
                  FCFA{data.price}
                </Typography>
                <Divider style={{ width: '57%', margin: '20px 0px 3px 2px' }} />
                <Typography
                  style={{
                    fontWeight: '500',
                    fontSize: 14,
                    color: '#2b2c2e',
                    fontFamily: 'Ubuntu'
                  }}
                  // variant="p"
                >
                  {data.description}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} lg={4} xl={4}>
              <Box className={classes.mainBox}>
                {errorOne && (
                  <Box
                    style={{
                      marginTop: 10
                    }}
                  >
                    <Typography
                      variant="p"
                      style={{
                        color: '#eb6558',
                        fontWeight: 900,
                        fontSize: 16
                      }}
                    >
                      {eresponseOne}
                    </Typography>
                  </Box>
                )}
                {cardError && (
                  <Box
                    style={{
                      marginTop: 10
                    }}
                  >
                    <Typography
                      variant="p"
                      style={{
                        color: '#eb6558',
                        fontWeight: 900,
                        fontSize: 16
                      }}
                    >
                      {cardResponseError}
                    </Typography>
                  </Box>
                )}
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-address"
                  style={{
                    fontWeight: 600,
                    fontSize: 17,
                    marginTop: 10,
                    letterSpacing: 1
                  }}
                >
                  <Typography variant="p">Quantity</Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="Quantity"
                  margin="normal"
                  name="quantity"
                  type="number"
                  id="bootstrap-input-name"
                  required
                  autoFocus
                  value={quantity}
                  style={{ marginRight: 8, marginBottom: 10 }}
                  onChange={handleQuantityChange}
                />
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-address"
                  style={{
                    color: '#1a1a1ab3',
                    fontWeight: 600,
                    fontSize: 20,
                    letterSpacing: 1
                  }}
                ></InputLabel>
                {isCode && (
                  <>
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input-address"
                      style={{
                        fontWeight: 600,
                        fontSize: 17,
                        marginTop: 10,
                        letterSpacing: 1
                      }}
                    >
                      <Typography variant="p">Full name</Typography>
                    </InputLabel>
                    <BootstrapInput
                      fullWidth
                      placeholder="name"
                      margin="normal"
                      name="clientName"
                      type="text"
                      id="bootstrap-input-name"
                      required
                      style={{ marginRight: 8 }}
                      value={inputs?.clientName}
                      onChange={handleChange}
                    />
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input-address"
                      style={{
                        fontWeight: 600,
                        fontSize: 17,
                        marginTop: 10,
                        letterSpacing: 1
                      }}
                    >
                      <Typography variant="p">Email address</Typography>
                    </InputLabel>
                    <BootstrapInput
                      fullWidth
                      placeholder="email"
                      margin="normal"
                      name="email"
                      type="text"
                      id="bootstrap-input-email"
                      required
                      style={{ marginRight: 8 }}
                      value={inputs?.email}
                      onChange={handleChange}
                    />
                  </>
                )}
                <Box className={classes.boxOne}>
                  <Box>{PaymentsDisplay()}</Box>
                </Box>
                {data.cardAllowed && (
                  <Box className={classes.paymentBox}>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: 10,
                        marginBottom: 10
                      }}
                    >
                      <Divider
                        style={{
                          height: 1,
                          width: 100,
                          marginRight: 10,
                          backgroundColor: '#d6d7dfe3'
                        }}
                      />
                      <Typography>OR</Typography>
                      <Divider
                        style={{
                          height: 1,
                          width: 100,
                          marginLeft: 10,
                          backgroundColor: '#d6d7dfe3'
                        }}
                      />
                    </Box>
                    <Button
                      onClick={handleCardSubmit}
                      color="primary"
                      variant="contained"
                      className={classes.cardButton}
                    >
                      {isCardButtonLoading ? (
                        <CircularProgress
                          className={classes.circularProgress}
                          size="14px"
                        />
                      ) : (
                        'Pay with Card'
                      )}{' '}
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} lg={4} xl={4}>
              <ServiceList />
            </Grid>
          </Grid>
        )}
      </Box>

      <Footer />
    </Page>
  );
};

export default ViewProductWithId;
