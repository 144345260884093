export default {
  body: { fontFamily: ['Ubuntu', 'sans-serif'].join(','), color: '#546e7a' },
  h1: {
    fontWeight: 700,
    fontSize: 35,
    color: '#1a1f36',
    letterSpacing: '-0.24px',
    fontFamily: ['Ubuntu', 'sans-serif'].join(',')
  },
  h2: {
    fontWeight: 700,
    fontSize: 29,
    color: '#1a1f36',
    letterSpacing: '-0.24px',
    fontFamily: ['Ubuntu', 'sans-serif'].join(',')
  },
  h3: {
    fontWeight: 700,
    fontSize: 24,
    color: '#1a1f36',
    letterSpacing: '.9px',
    fontFamily: ['Ubuntu', 'sans-serif'].join(',')
  },
  h4: {
    fontWeight: 900,
    fontSize: 28,
    color: 'rgb(26 27 37)',
    display: 'inline-block',
    letterSpacing: '1px',
    lineHeight: 0.5,
    wordWrap: 'break-word',
    fontFamily: ['Ubuntu', 'sans-serif'].join(',')
  },
  h5: {
    fontWeight: 700,
    fontSize: 18,
    letterSpacing: '.9px',
    color: 'rgb(26 27 37)',
    fontFamily: ['Ubuntu', 'sans-serif'].join(',')
  },
  h6: {
    fontWeight: 600,
    fontSize: 14,
    color: 'rgb(26 27 37)',
    letterSpacing: '.8px'.replace,
    fontFamily: ['Ubuntu', 'sans-serif'].join(',')
  },
  overline: {
    fontWeight: 500
  },
  p: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    fontSize: 12,
    color: '#546e7a'
  }
};
