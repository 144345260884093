import React, { useState, useEffect } from 'react';
import Page from 'src/components/Page';
import {
  Box,
  Typography,
  Button,
  Container,
  Toolbar
  //   Divider
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useAuthState } from 'src/context';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Footer from 'src/components/Footer';

const useStyles = makeStyles(theme => ({
  root: { marginBottom: 100 },
  inputfield: {
    width: '100%'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  buttons: {
    backgroundColor: '#ffffff',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
    border: '1px solid #ced4da',
    borderRadius: 20,
    height: 25,
    marginRight: 4,
    '&:hover': {
      color: 'black',
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
      border: '1px solid #ffffff'
    }
  },
  button: {
    display: 'block',
    margin: '10px 0px 10px 0px',
    width: 200
  },
  button2: {
    height: 35,
    borderRadius: 8
  },
  pIcons: {
    color: '#8b8b95',
    height: 30,
    width: 30,
    marginTop: 15
  },
  btn: {
    textTransform: 'none',
    marginLeft: -7,
    '&:hover': {
      color: 'black',
      backgroundColor: '#ffffff'
    }
  },
  invoice: {
    border: '1px solid rgb(225 232 241 / 73%)',
    padding: '10px',
    borderRadius: '5px',
    width: '350px',
    backgroundColor: 'rgb(250 252 254)',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  },
  mainBox: {
    width: '400px',
    borderRadius: 4,
    padding: '5px 25px 25px 25px',
    zIndex: 1000,
    borderLeft: '2px dashed rgb(234 235 255)',
    borderRight: '2px dashed rgb(234 235 255)',
    marginLeft: '35%',
    backgroundColor: '#ffffff',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
      marginLeft: 'auto'
    }
  },
  boxOne: {
    alignItems: 'center',
    width: 400,
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  },
  boxTwo: {
    alignItems: 'center',
    width: 400,
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  },
  paymentBox: {
    borderRadius: '5px',
    width: '350px',
    padding: 10,
    marginTop: 10,
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 7%)',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  }
}));

const LinkView = () => {
  const classes = useStyles();
  const { token } = useAuthState();
  const params = useParams();
  const [data, setData] = useState([]);
  const id = params?.id;

  useEffect(() => {
    const fetchProductData = async id => {
      await axios({
        method: 'get',
        url: 'https://api.fapshi.com/transPayDetails/' + id,
        headers: { 'x-access-token': token }
      })
        .then(res => {
          setData(res.data);
        })
        .catch(error => {
          console.log(error);
          console.log(data);
        });
    };
    fetchProductData(id);
  }, []);

  return (
    <Page className={classes.introtitle} title="payment-receipt">
      <Box className={classes.root}>
        <Toolbar
          style={{
            boxShadow: 'none',
            minHeight: 40,
            width: '100%',
            display: 'inline-flex',
            justifyContent: 'space-between',
            backgroundColor: '#ffffff',
            borderBottom: '1px solid rgb(234 235 255)'
          }}
        >
          <Typography
            style={{
              fontFamily: 'ubuntu',
              fontWeight: 900,
              color: '#3f51b5',
              fontSize: 16
            }}
            sx={{ ml: 2, flex: 1 }}
            variant="h6"
            component="div"
          >
            Fapshi Payment receipt
          </Typography>
          <Button
            color="primary"
            variant="outlined"
            className={classes.buttons}
            style={{
              textTransform: 'none',
              height: 25,
              fontSize: 14,
              margin: '4px 0px 0px 20px',
              borderRadius: 20
            }}
            size="small"
            onClick={() => window.print()}
          >
            Print receipt
          </Button>
        </Toolbar>
        <Container>
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'center'
            }}
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              style={{
                borderLeft: '1px solid rgb(234 235 255)',
                borderRight: '1px solid rgb(234 235 255)',
                paddingTop: 1
              }}
            >
              <Box className={classes.mainBox}>
                <Box style={{ marginBottom: 30 }}>
                  <Typography variant="p"> </Typography>
                </Box>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h4">Payment details</Typography>
                    <br />
                    <Typography variant="p">
                      Created on{' '}
                      <span
                        style={{
                          color: '#546e7a',
                          fontSize: 14,
                          marginLeft: 3
                        }}
                      >
                        {data.dateInitiated} (WAT)
                      </span>{' '}
                    </Typography>
                    <br />
                  </Grid>
                  <Grid item xs={12}>
                    {data.clientName && (
                      <>
                        <Typography variant="p">
                          Dear
                          <span
                            style={{
                              fontSize: 18,
                              marginLeft: 3,
                              fontWeight: 500
                            }}
                          >
                            {data.clientName},
                          </span>{' '}
                        </Typography>
                        <br />
                      </>
                    )}
                    <Typography variant="p">
                      <span
                        style={{
                          color: '#546e7a',
                          fontSize: 13,
                          marginLeft: 3
                        }}
                      >
                        Your purchase from {data.vendorName} was successful!
                        These are the details of the Transaction:{' '}
                      </span>{' '}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="p">
                      Transaction number{' '}
                      <span
                        style={{
                          color: '#546e7a',
                          fontSize: 14,
                          marginLeft: 3
                        }}
                      >
                        {data.transferId}
                      </span>{' '}
                    </Typography>
                    <br />
                    <Typography variant="p">
                      Date of payment{' '}
                      <span
                        style={{
                          color: '#546e7a',
                          fontSize: 14,
                          marginLeft: 3
                        }}
                      >
                        {data.dateConfirmed}
                      </span>{' '}
                    </Typography>{' '}
                    <br />
                    <Typography variant="p">
                      Payment Method{' '}
                      <span
                        style={{
                          color: '#546e7a',
                          fontSize: 14,
                          marginLeft: 3
                        }}
                      >
                        {data.medium}
                      </span>{' '}
                    </Typography>
                    {data.clientPhone && (
                      <>
                        <br />
                        <Typography variant="p">
                          Account number{' '}
                          <span
                            style={{
                              color: '#546e7a',
                              fontSize: 14,
                              marginLeft: 3
                            }}
                          >
                            {data.clientPhone}
                          </span>{' '}
                        </Typography>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="p">
                      Product Id{' '}
                      <span
                        style={{
                          color: '#546e7a',
                          fontSize: 14,
                          marginLeft: 3
                        }}
                      >
                        {data.productId}
                      </span>
                    </Typography>
                    <br />
                    <Typography variant="p" v>
                      Product name{'  '}
                      <span
                        style={{
                          color: '#546e7a',
                          fontSize: 14,
                          marginLeft: 3
                        }}
                      >
                        {data.productName}
                      </span>
                    </Typography>
                    <br />
                    <Typography variant="p">
                      Vendor Id{' '}
                      <span
                        style={{
                          color: '#546e7a',
                          fontSize: 14,
                          marginLeft: 3
                        }}
                      >
                        {data.vendorId}
                      </span>
                    </Typography>
                    <br />{' '}
                    <Typography variant="p">
                      Vendor Name{' '}
                      <span
                        style={{
                          color: '#546e7a',
                          fontSize: 14,
                          marginLeft: 3
                        }}
                      >
                        {data.vendorName}
                      </span>
                    </Typography>
                    <br />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="p">
                      Total amount paid{' '}
                      <span
                        style={{
                          fontSize: 25,
                          marginLeft: 3,
                          fontWeight: 700
                        }}
                      >
                        {data.total} XAF
                      </span>
                    </Typography>
                    <br />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </Page>
  );
};

export default LinkView;
