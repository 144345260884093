import React from 'react';
import { Navigate } from 'react-router-dom';
import MainLayout from 'src/layouts/MainLayout';
import InvoiceView from 'src/views/Invoices/InvoiceView';
import InvoiceReceipt from 'src/views/Invoices/Receipt';
import ViewProductWithId from 'src/views/products/ProductsView/ViewProductWithId';
import ProductReceipt from 'src/views/products/ProductsView/Receipt';
import SimpleLinkView from 'src/views/links/LinkView/LinkView';
import DonationLinkView from 'src/views/links/LinkView/DonationView';
import DonationReceipt from 'src/views/links/LinkView/Receipt';
import Downloads from 'src/views/links/LinkView/Download';
import NotFoundView from 'src/views/errors/NotFoundView';
import SuccessPage from 'src/views/Results/Success';
import TestSuccessPage from 'src/views/Results/test/Success';
import FailurePage from 'src/views/Results/Success';
import Checkout from 'src/views/Merchant/Checkout';
import TestCheckout from 'src/views/Merchant/test/Checkout';

const routes = [
  {
    path: '/',
    element: <MainLayout />,
    isPrivate: false,
    children: [
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/register" /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: '/payment/:id', element: <Checkout /> },
      { path: '/test/payment/:id', element: <TestCheckout /> },
      { path: 'products/:id', element: <ViewProductWithId /> },
      { path: 'invoices/:id', element: <InvoiceView /> },
      { path: 'products/receipt/:id', element: <ProductReceipt /> },
      { path: 'donations/receipt/:id', element: <DonationReceipt /> },
      { path: 'invoices/receipt/:id', element: <InvoiceReceipt /> },
      { path: 'payment-link/details/download', element: <Downloads /> },
      { path: 'link/:id', element: <SimpleLinkView /> },
      { path: 'donation/:id', element: <DonationLinkView /> },
      { path: '/success/:id', element: <SuccessPage /> },
      { path: '/test/success/:id', element: <TestSuccessPage /> },
      { path: '/failure', element: <FailurePage /> }
    ]
  }
];

export default routes;
