import React from 'react';
import { initialState, CartReducer } from './reducer';

const CartStateContext = React.createContext();
const CartDispatchContext = React.createContext();

export const useCartState = () => {
  const context = React.useContext(CartStateContext);
  if (context === undefined) {
    throw new Error('useCartState can only be used within an CartProvider');
  }
  return context;
};

export const useCartDispatch = () => {
  const context = React.useContext(CartDispatchContext);
  if (context === undefined) {
    throw new Error('useCartDispatch can only be used within an CartProvider');
  }
  return context;
};

export const CartProvider = ({ children }) => {
  const [cart, dispatch] = React.useReducer(CartReducer, initialState);

  return (
    <CartStateContext.Provider value={cart}>
      <CartDispatchContext.Provider value={dispatch}>
        {children}
      </CartDispatchContext.Provider>
    </CartStateContext.Provider>
  );
};
