import React from 'react';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';
import Page from 'src/components/Page';
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  },
  button: {
    textAlign: 'center',
    marginTop: 10,
    marginLeft: 350,
    backgroundColor: '#000',
    color: '#fff',
    textTransform: 'none',
    fontSize: 18,
    fontWeight: 700,
    [theme.breakpoints.down('md')]: {
      marginLeft: 80
    },
    '&:hover': {
      backgroundColor: '#000'
    }
  }
}));

const NotFoundView = () => {
  const classes = useStyles();
  return (
    <Page className={classes.root} title="404">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Typography align="center" variant="h1">
            404: The page you are looking for isn’t here
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle2">
            You either tried some shady route or you came here by mistake.
            Whichever it is, try using the navigation
          </Typography>
          <a href="https://fapshi.com">
            <Button
              align="center"
              variant="contained"
              size="small"
              className={classes.button}
            >
              Return to home
            </Button>
          </a>
          <Box textAlign="center">
            <img
              alt="Under development"
              className={classes.image}
              src="/static/images/undraw_page_not_found_su7k.svg"
            />
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default NotFoundView;
