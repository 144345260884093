import React, { useState, useEffect } from 'react';
import Page from 'src/components/Page';
import { Box, Typography, Button, Container, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useAuthState } from 'src/context';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Footer from 'src/components/Footer';
import { FaAngleDown } from 'react-icons/fa';
import { FaAngleUp } from 'react-icons/fa';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from 'src/components/Snackbar';
import InputLabel from '@mui/material/InputLabel';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import DoneIcon from '@mui/icons-material/Done';

const useStyles = makeStyles(theme => ({
  root: { marginBottom: 100 },
  inputfield: {
    width: '100%'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  buttons: {
    backgroundColor: '#ffffff',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
    border: '1px solid #ced4da',
    borderRadius: 20,
    height: 25,
    marginRight: 4,
    '&:hover': {
      color: 'black',
      backgroundColor: '#ffffff',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
      border: '1px solid #ffffff'
    }
  },
  button: {
    display: 'block',
    margin: '10px 0px 10px 0px',
    width: 200
  },

  pIcons: {
    color: '#8b8b95',
    height: 30,
    width: 30,
    marginTop: 15
  },
  btn: {
    textTransform: 'none',
    marginLeft: -7,
    '&:hover': {
      color: 'black',
      backgroundColor: '#ffffff'
    }
  },
  invoice: {
    border: '1px solid rgb(225 232 241 / 73%)',
    padding: '10px',
    borderRadius: '5px',
    width: '350px',
    backgroundColor: 'rgb(250 252 254)',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  },
  mainBox: {
    width: '500px',
    borderRadius: 4,
    padding: '5px 25px 25px 25px',
    zIndex: 1000,
    borderLeft: '2px dashed rgb(234 235 255)',
    borderRight: '2px dashed rgb(234 235 255)',
    marginLeft: '30%',
    backgroundColor: '#ffffff',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
      marginLeft: 'auto'
    }
  },
  mainBoxTwo: {
    padding: '0px',
    borderRadius: '5px',
    marginLeft: 'auto',
    marginTop: 15,
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  },
  boxOne: {
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  },
  boxTwo: {
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  },
  paymentBox: {
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  },
  button2: {
    height: 45,
    borderRadius: 8,
    backgroundColor: '#000000e3',
    '&:hover': {
      backgroundColor: '#000000e3',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
      color: '#fff'
    }
  },
  cardButton: {
    height: 45,
    color: '#fff',
    width: '28rem',
    borderRadius: 8,
    textTransform: 'capitalize',
    backgroundColor: '#000000e3',
    '&:hover': {
      backgroundColor: '#000000e3',
      boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 15%)',
      color: '#fff'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  cancelBtn: {
    fontSize: 14,
    textTransform: 'none',
    color: '#c10e0e',
    textDecoration: 'underline',
    margin: 0,
    padding: 0,
    '&:hover': {
      backgroundColor: '#fff'
    }
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1.5px solid #d6d8dd',
    fontSize: 16,
    width: '100%',
    height: '20px',
    padding: '10px 12px',
    boxShadow: '0 1.2px 5.68px 0 rgb(0 0 0 / 10%)',
    borderRadius: 7,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    marginTop: 0,
    // Use the system font instead of the default Roboto font.
    fontFamily: ['Ubuntu'].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#a7aecf',
      borderRadius: 10
    },
    '&:hover': {
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      // borderColor: '#e3e8fd',
      borderRadius: 10
    }
  }
}));

const LinkView = () => {
  const classes = useStyles();

  const { token } = useAuthState();

  const params = useParams();

  const [data, setData] = useState([]);
  const [snack, setSnack] = useState(false);
  const [esnack, setESnack] = useState(false);

  const [errorOne, setErrorOne] = useState(false);
  const [eresponseOne, setEresponseOne] = useState(null);
  const [isLoadingOne, setIsLoadingOne] = useState(false);

  const [errorThree, setErrorThree] = useState(false);
  const [eresponseThree, setEresponseThree] = useState(null);
  const [isLoadingThree, setIsLoadingThree] = useState(false);
  const id = params?.id;

  useEffect(() => {
    const fetchProductData = async id => {
      await axios({
        method: 'get',
        url: 'https://api.fapshi.com/invoice/' + id,
        headers: { 'x-access-token': token }
      })
        .then(res => {
          setData(res.data);
        })
        .catch(error => {
          console.log(error);
          navigate('/', { replace: true });
        });
    };
    fetchProductData(id);
  }, []);

  const [show, setShow] = useState(true);

  const [isCode, setIscode] = useState(true);
  const [code, setCode] = useState(null);
  const [cardError, setCardError] = useState(false);
  const [cardResponseError, setCardResponseError] = useState(null);
  const [isCardButtonLoading, setIsCardButtonLoading] = useState(false);

  const [inputs, setInputs] = useState({
    vendorName: null,
    amount: null,
    clientName: null,
    email: data.clientEmail,
    phone: null
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setInputs({ ...inputs, [name]: value });
  };

  const handleMomo = () => {
    setIsLoadingOne(true);
    const values = {
      phone: inputs?.phone
    };
    axios({
      method: 'post',
      url: 'https://api.fapshi.com/invoicePay/momo/' + id,
      data: values
    })
      .then(() => {
        setIscode(false);
        setIsLoadingOne(false);
      })
      .catch(err => {
        if (err.response.status === 402) {
          axios({
            method: 'get',
            url:
              'https://api.fapshi.com/transaction/product/' +
              err.response.data.uuid
          })
            .then(res => {
              console.log(res.data);
              if (res.data.status === 'SUCCESSFUL') {
                setSnack(true);
                navigate('/success/' + res.data.transferId, { replace: true });
              } else if (res.data.status === 'FAILED') {
                setESnack(true);
              }
              setIscode(true);
              setInputs(' ');
              setIsLoadingOne(false);
              setCode(null);
            })
            .catch(() => {
              setIsLoadingOne(false);
              setEresponseThree(null);
              setCode(null);
            });
        } else {
          setIsLoadingOne(false);
        }
        setErrorOne(true);
        setEresponseOne(err.response?.data.message);
      });
  };

  const handleSMS = () => {
    setIsLoadingThree(true);
    const values = {
      code,
      phone: inputs?.phone
    };
    axios({
      method: 'post',
      url: 'https://api.fapshi.com/product/confirmsmscode',
      data: values
    })
      .then(res => {
        axios({
          method: 'get',
          url: 'https://api.fapshi.com/transaction/product/' + res.data.uuid
        })
          .then(res => {
            console.log(res.data);
            if (res.data.status === 'SUCCESSFUL') {
              setSnack(true);
              navigate('/success/' + res.data.transferId, { replace: true });
            } else if (res.data.status === 'FAILED') {
              setESnack(true);
            }
            setIscode(true);
            setInputs(' ');
            setIsLoadingThree(false);
            setCode(null);
          })
          .catch(() => {
            setIsLoadingThree(false);
            setEresponseThree(null);
            setCode(null);
          });
      })
      .catch(err => {
        setIsLoadingThree(false);
        setErrorThree(true);
        setEresponseThree(err.response?.data.message);
      });
  };

  const handleCardSubmit = () => {
    setIsCardButtonLoading(true);
    axios({
      method: 'post',
      url: 'https://api.fapshi.com/invoicepay/card/' + data.getCode
    })
      .then(res => {
        window.location.assign(res.data.link);
      })
      .catch(err => {
        setIsCardButtonLoading(false);
        setCardError(true);
        setCardResponseError(err.response?.data.message);
      });
  };

  const handleClose = () => {
    setSnack(false);
  };
  const handleEClose = () => {
    setESnack(false);
  };

  const handleCancel = () => {
    setIscode(true);
    setIsLoadingThree(false);
    setCode(null);
    setInputs(null);
    setEresponseOne(null);
    setEresponseThree(null);
  };

  const PaymentsDisplay = () => {
    return (
      <Box className={classes.paymentBox}>
        {snack && (
          <Snackbar
            duration={6000}
            open={snack}
            close={handleClose}
            severity="success"
            data="Payments completed successfully"
          />
        )}
        {esnack && (
          <Snackbar
            duration={6000}
            severity="error"
            open={esnack}
            close={handleEClose}
            data="Transaction failed. Payments not confirmed"
          />
        )}
        {isCode ? (
          <>
            <Box className={classes.paymentBox}>
              <Box style={{ marginBottom: 15 }}>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input-address"
                  style={{
                    fontWeight: 600,
                    fontSize: 17,
                    marginTop: 10,
                    letterSpacing: 1
                  }}
                >
                  <Typography variant="p">Payment number</Typography>
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  placeholder="ex: 6xxxxxxxx"
                  margin="normal"
                  name="phone"
                  type="number"
                  id="bootstrap-input-phone"
                  value={inputs?.phone}
                  onChange={handleChange}
                  required
                  style={{ marginRight: 8, marginBottom: -15 }}
                />
              </Box>
              <Button
                color="primary"
                style={{ textTransform: 'none', marginTop: 10 }}
                variant="contained"
                fullWidth
                className={classes.button2}
                onClick={handleMomo}
                disabled={isLoadingOne}
              >
                {isLoadingOne ? (
                  <CircularProgress
                    className={classes.circularProgress}
                    size="14px"
                  />
                ) : (
                  'Pay with Momo Or OM'
                )}{' '}
              </Button>
            </Box>
          </>
        ) : (
          <>
            {errorThree && (
              <Typography
                variant="p"
                style={{
                  color: '#eb6558',
                  fontWeight: 900,
                  fontSize: 15
                }}
              >
                {eresponseThree}
              </Typography>
            )}
            <Box style={{ marginBottom: 5 }}>
              <Typography
                style={{
                  fontSize: 14,
                  fontFamily: 'Ubuntu',
                  color: '#00000099'
                }}
              >
                An SMS code has been sent to your phone number. Please fill in
                this code.
              </Typography>
              <BootstrapInput
                fullWidth
                placeholder="Enter code"
                margin="normal"
                name="code"
                type="text"
                id="bootstrap-input-qty"
                value={code}
                onChange={e => setCode(e.target.value)}
                required
                style={{ marginRight: 8 }}
              />
              <Button onClick={handleCancel} className={classes.cancelBtn}>
                Cancel transaction
              </Button>
            </Box>
            <Button
              color="primary"
              style={{ textTransform: 'none', marginTop: 5 }}
              variant="contained"
              fullWidth
              className={classes.button2}
              onClick={handleSMS}
              disabled={isLoadingThree}
            >
              {isLoadingThree ? (
                <CircularProgress
                  className={classes.circularProgress}
                  size="14px"
                />
              ) : (
                'Confirm'
              )}{' '}
            </Button>
          </>
        )}
      </Box>
    );
  };
  const navigate = useNavigate();
  return (
    <Page className={classes.introtitle} title="view product">
      <Box className={classes.root}>
        <Container>
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'center'
            }}
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              style={{
                borderLeft: '1px solid rgb(234 235 255)',
                borderRight: '1px solid rgb(234 235 255)',
                paddingTop: 1
              }}
            >
              <Box className={classes.mainBox}>
                <Box style={{ marginBottom: 30 }}>
                  <Typography variant="p">{data.description} </Typography>
                </Box>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <Typography variant="h4">Invoice details</Typography>
                      {data.status === 'SUCCESSFUL' && (
                        <Stack direction="row" spacing={1}>
                          <Chip
                            label="Paid"
                            onDelete={() => {}}
                            deleteIcon={<DoneIcon />}
                            variant="outlined"
                            size="small"
                            color="success"
                          />
                        </Stack>
                      )}
                    </Box>
                    <br />
                    <Typography variant="p">
                      Created on{' '}
                      <span
                        style={{
                          color: '#3a3b3c',
                          fontSize: 14,
                          marginLeft: 3
                        }}
                      >
                        {data.dateInitiated} (WAT)
                      </span>{' '}
                    </Typography>
                    <br />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="p">
                      Invoice code{' '}
                      <span
                        style={{
                          color: '#3a3b3c',
                          fontSize: 14,
                          marginLeft: 3
                        }}
                      >
                        {data.invoiceCode}
                      </span>{' '}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="p">
                      To{' '}
                      <span
                        style={{
                          color: '#3a3b3c',
                          fontSize: 14,
                          marginLeft: 3
                        }}
                      >
                        {data.clientName}
                      </span>
                    </Typography>
                    <br />
                    <Typography variant="p" v>
                      Email{'  '}
                      <span
                        style={{
                          color: '#3a3b3c',
                          fontSize: 14,
                          marginLeft: 3
                        }}
                      >
                        {data.clientEmail}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box style={{ marginLeft: 'auto', textAlign: 'Right' }}>
                      <Typography variant="p">
                        From{' '}
                        <span
                          style={{
                            color: '#3a3b3c',
                            fontSize: 14,
                            marginLeft: 3
                          }}
                        >
                          {data.vendorName}
                        </span>
                      </Typography>
                      <br />
                      <Typography style={{ textAlign: 'right' }} variant="p">
                        Vendor Id{' '}
                        <span
                          style={{
                            color: '#3a3b3c',
                            fontSize: 14,
                            marginLeft: 3
                          }}
                        >
                          {data.vendorId}
                        </span>{' '}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h1">FCFA{data.total}</Typography>
                    <br />
                  </Grid>
                  {data.invoiceContent?.length > 0 && (
                    <>
                      <Grid item xs={12}>
                        <Button
                          endIcon={show ? <FaAngleDown /> : <FaAngleUp />}
                          className={classes.btn}
                          onClick={() => setShow(!show)}
                        >
                          <Typography variant="h5">
                            View items details
                          </Typography>
                        </Button>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Box>
                      <Box display={show ? 'none' : 'block'}>
                        <Divider
                          style={{ backgroundColor: 'rgb(247 249 250)' }}
                        />
                        {data.invoiceContent?.map(res => (
                          <>
                            <Box
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                              }}
                            >
                              <Typography
                                style={{ color: '#3a3b3c', fontSize: 16 }}
                                variant="p"
                              >
                                Item name
                              </Typography>
                              <Typography
                                style={{ color: '#3a3b3c', fontSize: 16 }}
                                variant="p"
                              >
                                {res.item}
                              </Typography>
                            </Box>
                            <Box
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                              }}
                            >
                              <Typography
                                style={{ color: '#3a3b3c', fontSize: 16 }}
                                variant="p"
                              >
                                Quantity
                              </Typography>
                              <Typography
                                style={{ color: '#3a3b3c', fontSize: 16 }}
                                variant="p"
                              >
                                {res.qty}
                              </Typography>
                            </Box>
                            <Box
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                              }}
                            >
                              <Typography
                                style={{ color: '#3a3b3c', fontSize: 16 }}
                                variant="p"
                              >
                                Unit price
                              </Typography>
                              <Typography
                                style={{ color: '#3a3b3c', fontSize: 16 }}
                                variant="p"
                              >
                                FCFA{res.unitPrice}
                              </Typography>
                            </Box>
                            <Box
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                              }}
                            >
                              <Typography
                                style={{ color: '#3a3b3c', fontSize: 17 }}
                                variant="p"
                              >
                                Sub total
                              </Typography>
                              <Typography
                                style={{ color: '#3a3b3c', fontSize: 17 }}
                                variant="p"
                              >
                                FCFA{res.unitPrice * res.qty}
                              </Typography>
                            </Box>
                            <Divider
                              style={{ backgroundColor: 'rgb(247 249 250)' }}
                            />
                          </>
                        ))}
                      </Box>
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Typography style={{ fontSize: 16 }} variant="p">
                          Shipping cost
                        </Typography>
                        <Typography style={{ fontSize: 16 }} variant="p">
                          FCFA{data.deliveryFee || 0}
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Typography style={{ fontSize: 16 }} variant="p">
                          Total
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 20,
                            fontWeight: 700
                          }}
                          variant="p"
                        >
                          FCFA{data.total}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    {data.reason && (
                      <>
                        <Typography variant="h6">Note</Typography>
                        <Typography variant="p" style={{ color: '#3a3b3c' }}>
                          {data.reason}
                        </Typography>
                        <br />
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Box className={classes.mainBoxTwo}>
                      <InputLabel
                        shrink
                        htmlFor="bootstrap-input-address"
                        style={{
                          color: '#1a1a1ab3',
                          fontWeight: 600,
                          fontSize: 20,
                          letterSpacing: 1
                        }}
                      ></InputLabel>
                      {errorOne && (
                        <Box
                          style={{
                            marginTop: 10
                          }}
                        >
                          <Typography
                            variant="p"
                            style={{
                              color: '#eb6558',
                              fontWeight: 900,
                              fontSize: 16
                            }}
                          >
                            {eresponseOne}
                          </Typography>
                        </Box>
                      )}
                      {cardError && (
                        <Box
                          style={{
                            marginTop: 10
                          }}
                        >
                          <Typography
                            variant="p"
                            style={{
                              color: '#eb6558',
                              fontWeight: 900,
                              fontSize: 16
                            }}
                          >
                            {cardResponseError}
                          </Typography>
                        </Box>
                      )}
                      {isCode && (
                        <>
                          <InputLabel
                            shrink
                            htmlFor="bootstrap-input-address"
                            style={{
                              fontWeight: 600,
                              fontSize: 17,
                              marginTop: 10,
                              letterSpacing: 1
                            }}
                          >
                            <Typography variant="p">Full name</Typography>
                          </InputLabel>
                          <BootstrapInput
                            fullWidth
                            placeholder="name"
                            margin="normal"
                            name="clientName"
                            type="text"
                            disabled
                            id="bootstrap-input-name"
                            required
                            style={{ marginRight: 8 }}
                            value={data.clientName}
                            onChange={handleChange}
                          />
                          <InputLabel
                            shrink
                            htmlFor="bootstrap-input-address"
                            style={{
                              fontWeight: 600,
                              fontSize: 17,
                              marginTop: 10,
                              letterSpacing: 1
                            }}
                          >
                            <Typography variant="p">Email address</Typography>
                          </InputLabel>
                          <BootstrapInput
                            fullWidth
                            placeholder="email"
                            margin="normal"
                            name="email"
                            type="text"
                            id="bootstrap-input-email"
                            required
                            style={{ marginRight: 8 }}
                            value={data.clientEmail}
                            disabled
                            onChange={handleChange}
                          />
                        </>
                      )}
                      <Box className={classes.boxOne}>
                        <Box>{PaymentsDisplay()}</Box>
                      </Box>
                      {data.cardAllowed && (
                        <Box className={classes.paymentBox}>
                          <Box
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginTop: 10,
                              marginBottom: 10
                            }}
                          >
                            <Divider
                              style={{
                                height: 1,
                                width: 100,
                                marginRight: 10,
                                backgroundColor: '#d6d7dfe3'
                              }}
                            />
                            <Typography>OR</Typography>
                            <Divider
                              style={{
                                height: 1,
                                width: 100,
                                marginLeft: 10,
                                backgroundColor: '#d6d7dfe3'
                              }}
                            />
                          </Box>
                          <Button
                            onClick={handleCardSubmit}
                            color="primary"
                            variant="contained"
                            className={classes.cardButton}
                          >
                            {isCardButtonLoading ? (
                              <CircularProgress
                                className={classes.circularProgress}
                                size="14px"
                              />
                            ) : (
                              'Pay with Card'
                            )}{' '}
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </Page>
  );
};

export default LinkView;
