import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from '@material-ui/core/Container';
import { Button, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FaFileDownload } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 50,
    width: 700
  },
  button: {
    textTransform: 'none',
    float: 'right',
    borderRadius: 10,
    fontSize: 16,
    marginBottom: 5,
    '&:hover': {
      backgroundColor: '#ffffff'
    }
  }
}));

export default function DenseTable() {
  const classes = useStyles();
  const location = useLocation();
  const rows = location.state?.data;
  const { organisation } = location.state;
  return (
    <Container className={classes.container}>
      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Typography
            style={{
              fontFamily: 'ubuntu',
              fontWeight: 700,
              fontSize: 16,
              color: '#3f51b5'
            }}
          >
            {organisation}
          </Typography>
        </Box>
        <Box>
          <Button
            startIcon={<FaFileDownload size={14} />}
            size="small"
            className={classes.button}
            onClick={() => window.print()}
          >
            Download
          </Button>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right">Date of payment</TableCell>
              <TableCell align="right">Payment method</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {rows.payerName ? rows.payerName : 'Anonymous'}
                </TableCell>
                <TableCell align="right">{row.received} XAF</TableCell>
                <TableCell align="right">{row.dateConfirmed}</TableCell>
                <TableCell align="right">{row.medium}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
